.container {
  padding-block: 9rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
}

.quarterCircle {
  width: 275px;
  height: 275px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0a9da0;
  clip-path: circle(50% at 0 0);
}

.content {
  width: clamp(33ch, 85%, 110ch);
}

.intro {
  display: flex;
  flex-wrap: wrap;
}

.intro > *:nth-child(1) {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
}

.intro > *:nth-child(2) {
  flex: 1 1 50%;
  display: flex;
  justify-content: center;
}

.intro h1 {
  font-size: 2.1rem;
  font-family: "Gabarito", sans-serif;
}

.intro p {
  line-height: 1.5em;
}

.intro img {
  margin-left: 3rem;
}

.skills {
  display: flex;
  flex-direction: column;
}

.tech {
  display: flex;
  font-weight: bold;
}

.tech p {
  margin-top: 0;
  position: relative;
  font-family: "Gabarito", sans-serif;
  font-size: 1.1rem;
}

.tech p::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0px;
  width: 100%;
  height: 3px;
  border-radius: 100rem;
  background-color: #006d77;
}

.icons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.htmlIcon {
  margin-right: 1rem;
  color: #e34c26;
}

.cssIcon {
  margin-right: 1rem;
  color: #2965f1;
}

/* To style the "js" letters in the JavaScript icon whit the color #323330 */
.javascriptIconContainer {
  position: relative;
  margin-right: 1rem;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  z-index: -1;
}

.javascriptIconContainer::before {
  content: "";
  position: absolute;
  bottom: 12px;
  right: 14px;
  width: 35px;
  height: 35px;
  background-color: #323330;
  z-index: -2;
}
/* -------------------------------------------------------- */

.javascriptIcon {
  color: #f0db4f;
}

.reactIcon {
  margin-right: 1rem;
  color: #61dbfb;
}

.tailwindIcon {
  color: #4dc0b5;
}

@media (max-width: 861px) {
  .container {
    padding-top: 4rem;
    padding-bottom: 7rem;
  }
  .intro {
    flex-direction: column-reverse;
  }
  .intro > *:nth-child(1) {
    text-align: center;
  }
  .intro img {
    margin-left: 0;
  }
  .tech {
    margin-top: 2rem;
    margin-bottom: 0.5rem;

    justify-content: center;
  }
  .icons {
    justify-content: center;
  }
}
