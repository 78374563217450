.container {
  padding-top: 9rem;
  padding-bottom: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
}

.content {
  width: clamp(38ch, 85%, 110ch);
}

.intro {
  margin-bottom: 3.25rem;
}

.intro h2 {
  font-family: "Gabarito", sans-serif;
  font-size: 1.55rem;
}

.intro h3 {
  font-family: "Gabarito", sans-serif;
  /* font-size: 1.2rem; */
}

.content > *:nth-child(2), .content > *:nth-child(3) {
  margin-bottom: 2.5rem;
}

.card {
  display: flex;
  flex-wrap: wrap;
}

.card > *:nth-child(1) {
  display: flex;
  flex: 1 1 53%;
  /* min-width: 20ch; */
  height: 260px;
}

.card > *:nth-child(2) {
  position: relative;
  padding-inline: 1rem;
  display: flex;
  flex: 1 1 47%;
  /* min-width: 20ch; */
  flex-direction: column;
  justify-content: center;
  border-radius: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  overflow: hidden;
}

.card img {
  width: 514px;
  height: 100%;
  border-radius: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.info h3 {
  margin-top: 0.2rem;
  font-family: "Gabarito", sans-serif;
}

.info p {
  margin-bottom: 0.75rem;
  line-height: 1.3em;
}

.doneInContainer {
  position: relative;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  margin-bottom: 0.75rem;
}

.doneInHeading {
  position: absolute;
  top: -25px;
  font-weight: bold;
}

.doneIn {
  font-style: italic;
}

.doneInContainer > *:nth-child(3) {
  margin-left: 1rem;
}

.linkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkContainer > *:nth-child(2) {
  margin-left: 1rem;
}

.link {
  text-decoration: none;
  color: inherit;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.icon {
  margin-left: 0.7rem;
}

.link:hover .icon {
  color: #333333;
}

.halfCircleInfo {
  position: absolute;
  right: 0;
  top: -5px;
  width: 150px;
  height: 150px;
  background-color: #006d77;
  clip-path: circle(50% at 100% 0);
}

.moreToCome {
  position: relative;
  margin-top: 2.5rem;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.moreToCome h3 {
  font-family: "Gabarito", sans-serif;
  font-size: 1.4rem;
}

.halfCircle {
  position: absolute;
  left: -23px;
  width: 200px;
  height: 200px;
  background-color: #006d77;
  clip-path: circle(50% at 0 50%);
}

/* .content, .moreToCome{
  animation: fade 3s linear both;
  animation-timeline: view();
  animation-range: entry 20% cover 30%;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

@media (max-width: 1140px) {
  .card img {
    width: 450px;
  }
}

@media (max-width: 998px) {
  .container {
    padding-top: 7rem;
  }
  .intro {
    text-align: center;
  }
  .card {
    border-radius: 1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  .card > *:nth-child(1) {
    justify-content: center;
  }
  .card img {
    box-shadow: none;
  }
  .card > *:nth-child(2) {
    margin-bottom: 2rem;
    text-align: center;
    box-shadow: none;
  }
  .halfCircleInfo {
    display: none;
  }
}

@media (max-width: 650px) {
  .container {
    padding-top: 6rem;
  }
  .intro {
    margin-bottom: 2.25rem;
  }
  .card > *:nth-child(1) {
    height: 220px;
  }
  .card img {
    width: 300px;
  }
  .icon {
    margin-left: 0.3rem;
  }
}

@media (max-width: 425px) {
  .moreToCome h3 {
    margin-left: 1rem;
  }
}
