footer {
  margin-top: 0.5rem;
  padding-block: 0.5rem;
  color: white;
  display: flex;
  background-color: #0a9396;
}

footer > * {
  flex-basis: 100%;
}

.col {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: small;
}

footer > *:nth-child(3) {
  margin-right: 0.25rem;
  font-style: italic;
}

.copyrightIcon {
  margin-right: 0.1rem;
}

.link {
  text-decoration: none;
  color: inherit;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.linkedinIcon {
  margin-right: 0.5rem;
}
