.container {
  position: relative;
  isolation: isolate;
  padding-block: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.container::after {
  content: "";
  /* background-image: linear-gradient(45deg, #84a98c, #006d77, #edf6f9 ); */
  background-image: linear-gradient(45deg, #84a98c, #006d77, #9fbec8);
  position: absolute;
  z-index: -1;
  inset: 0;
  transform: skewY(-5deg);
}

.content {
  width: clamp(33ch, 85%, 110ch);
  display: flex;
  flex-wrap: wrap;
  padding-block: 1rem;
}

.picture {
  position: relative;
  margin-top: 2rem;
  flex: 1 1 35%;
  display: flex;
  align-items: center;
  min-width: 30ch;
}

.picture img {
  width: 298px;
  height: 298px;
  border-radius: 50%;
}

.textContainer {
  margin-block: 2rem;
  flex: 1 1 65%;
  padding-inline: 1rem;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.textContainer h2 {
  margin-bottom: 1.7rem;
  font-size: 1.55rem;
  font-family: "Gabarito", sans-serif;
}

.textContainer p {
  line-height: 1.5rem;
}

@media (max-width: 1174px) {
  .picture img {
    width: 267px;
    height: 267px;
  }
}

@media (max-width: 922px) {
  .picture {
    margin-bottom: 0.25rem;
    justify-content: center;
  }
  .textContainer {
    padding-inline: 3.5rem;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .picture img {
    width: 241px;
    height: 241px;
  }
  .textContainer {
    padding-inline: 0.7rem;
  }
}
