.container {
  padding-bottom: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
}

.content {
  position: relative;
  padding-inline: 2rem;
  width: clamp(38ch, 85%, 110ch);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.quarterCircle {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 300px;
  background-color: #006d77;
  clip-path: circle(50% at 100% 0);
}

.intro {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.intro h3 {
  font-family: "Gabarito", sans-serif;
  font-size: 1.45rem;
}

.social {
  display: flex;
  margin-block: 0.25rem;
}

/* The Padding is used to make a gap between the a so the underline gets the "right" length */
.social a {
  position: relative;
  padding-inline: 0.3rem;
  transition: 300ms;
}

.social a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 2px;
  background-color: #e76f51;
  scale: 0 1;
  transition: scale 300ms;
}

.social a:hover::after {
  scale: 1 1;
}

.link {
  padding: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.info {
  margin-bottom: 1.7rem;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
}

.info > *:nth-child(1) {
  flex: 1 1 50%;
  display: flex;
  align-items: center;
}

.info > *:nth-child(2) {
  flex: 1 1 50%;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 0.75rem;
}

.info span {
  font-weight: bold;
}

.info a {
  text-decoration: none;
  color: inherit;
}

.info a:hover {
  color: #0056b3;
  text-decoration: underline;
}

@media (hover: none) {
  .social a::after {
    display: none;
    transition: none;
  }
  .social a:hover::after {
    scale: 0 1;
  }
}

@media (max-width: 650px) {
  .container {
    padding-bottom: 4rem;
  }
}
